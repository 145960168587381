<template>

        <ValidationObserver v-slot="{ handleSubmit }">
        <form ref="frmresetlogin" @submit.prevent="handleSubmit(ProcessResetPassword)">
            <div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <ValidationProvider name="current password" rules="required" v-slot="{ passed, failed, errors }">
                            <base-input id="currpassword" v-model="currpassword" type="password" label="Your current password" placeholder='current password'
                              autocomplete="chrome-off" :error="errors[0]" :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <ValidationProvider name="new password" rules="required|confirmed:confirmation"
                            v-slot="{ passed, failed, errors }">
                            <base-input  id="newpwd" v-model="password" type="password" label="New Password" autocomplete="chrome-off"
                              :error="errors[0]" :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                        </ValidationProvider>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <ValidationProvider name="Confirm New Password" vid="confirmation" rules="required"
                             v-slot="{ passed, failed, errors }">
                             <base-input id="confpass" v-model="confirmation" type="password" label="Confirm New Password"
                               autocomplete="chrome-off" :error="errors[0]" :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                             </base-input>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
        

    

       

          <div class="pt-4 float-right">
            <button :disabled="isSubmittingResetPassword" type="submit" class="btn">{{ btnResetPasswordText }}</button>
          </div>
          <small v-if="errorupdatepassword"><span style="color:#ec250d">* Sorry, your current password
              invalid</span></small>
        </form>
      </ValidationObserver>
      
</template>

<script>
export default {
data(){
    return{
        password: "",
      currpassword: "",
      confirmation: "",
      btnResetPasswordText: "Change Password",
      isSubmittingResetPassword: false,
      errorupdatepassword: false,
      two_factor_auth: false,
      two_factor_auth_type: null,
      isLoadingGetTwoFactorAuth: false,
      isLoadingSaveTwoFactorAuth: false,
      isLoadingGetGoogleTwoFactorAuth: false,
      isLoadingSaveGoogleTwoFactorAuth: false,
      qrCodeUrl: '',
      secretKey: '',
    }
    
},
methods: {
    revertViewMode() {
      const oriUsr = this.$global.getlocalStorage('userDataOri');
      //this.$global.SetlocalStorage('userData',oriUsr);
      localStorage.removeItem('userData');
      localStorage.removeItem('userDataOri');
      
      localStorage.setItem('userData',JSON.stringify(oriUsr));
      localStorage.removeItem('userDataOri');
      localStorage.removeItem('subdomainAgency');
      localStorage.removeItem('rootcomp');
      this.$store.dispatch('setUserData', {
              user: oriUsr,
      });
      window.document.location = "/configuration/agency-list/";
    },
    popupdatepasswordsuccess() {
      swal.fire({
        title: 'Change Password',
        text: 'your password has been updated!',
        timer: 2000,
        showConfirmButton: false,
        icon: 'success'
      });
    },
    ProcessResetPassword() {
      this.btnResetPasswordText = 'Change new password...';
      this.isSubmittingResetPassword = true;

      var userdata = this.$store.getters.userData

      this.$store.dispatch('updatePass', {
        usrID: userdata.id,
        newpassword: this.password,
        currpassword: this.currpassword,
      })
        .then(response => {
          if (response.result == 'success') {
            this.modals.updatepassword = false;
            this.popupdatepasswordsuccess();
          } else {
            this.btnResetPasswordText = "Reset Password";
            this.isSubmittingResetPassword = false;
            this.errorupdatepassword = true;
          }

        }, error => {
          this.btnResetPasswordText = "Reset Password";
          this.isSubmittingResetPassword = false;
          this.errorupdatepassword = true;

        })

    },
    popResetPassword() {

      this.password = "";
      this.confirmation = "";
      this.btnResetPasswordText = "Change Password";
      this.isSubmittingResetPassword = false;
      this.errorupdatepassword = false;
      this.modals.updatepassword = true;
    },
    logout() {
      localStorage.removeItem('companyGroupSelected');
      localStorage.removeItem('subdomainAgency');
      localStorage.removeItem('rootcomp');
      this.$global.selectsGroupCompany.companyGroupList = null;
      this.$store.dispatch('destroyToken')
        .then(response => {
          //this.$router.push({ name: 'Login' })
          window.document.location = '/login';
        })
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    async onOpenModalTwoFactorAuth(){
      const userData = this.$store.getters.userData
      this.isLoadingGetTwoFactorAuth = true
      await this.$store.dispatch('getSettingTwoFactorAuth', {
        userId: userData.id,
      }).then(response => {
        this.two_factor_auth = response.two_factor_auth
        this.two_factor_auth_type = response.two_factor_auth_type
        this.modals.twofactorauth = true;
      }, error => {
        this.$notify({
          type: 'primary',
          message: error.message,
          icon: 'fas fa-bug'
        })
      })
      this.isLoadingGetTwoFactorAuth = false
    },
    onCloseModalTwoFactorAuth(){
      this.two_factor_auth = false
      this.two_factor_auth_type = null
      this.modals.twofactorauth = false;
    },
    async onClickTwoFactorAuth(){
      if(this.two_factor_auth_type == 'google'){
          this.onCloseModalTwoFactorAuth();
          this.getGoogleTfa();
          this.modals.googleAuth = true
      } else {
        this.isLoadingSaveTwoFactorAuth = true
        const userData = this.$store.getters.userData
  
        const payload = {
          userId: userData.id,
          two_factor_auth: this.two_factor_auth,
          two_factor_auth_type: this.two_factor_auth_type,
        }
  
        await this.$store.dispatch('settingTwoFactorAuth', payload)
        .then(response => {
          this.$notify({
              type: 'success',
              message: response.message,
              icon: 'far fa-save'
          });
        }, error => {
          this.$notify({
            type: 'danger',
            message: error.message,
            icon: 'fa fa-save'
          })
        })
        this.isLoadingSaveTwoFactorAuth = false
        this.onCloseModalTwoFactorAuth()
      }
    },
    onHandleSelectTwoFactorAuth(value){
      if(this.two_factor_auth_type == value){
        this.two_factor_auth = false,
        this.two_factor_auth_type = null
      } else {
        this.two_factor_auth = true
        this.two_factor_auth_type = value
      }
    },
    async onHandleCancelGoogleTwoFactorAuth(){
      await this.onOpenModalTwoFactorAuth();
      this.modals.googleAuth = false;
    },
    async getGoogleTfa(){
      const userData = this.$store.getters.userData
      this.isLoadingGetGoogleTwoFactorAuth = true
      await this.$store.dispatch('getGoogleTfa', {
        userId: userData.id,
        companyId: userData.company_id,
      }).then(response => {
        let qrCodeXml = response.qrCodeUrl;
              const stringToRemoveFront = '<?xml version="1.0" encoding="UTF-8"?>\n';
              const stringToRemoveBack = '\n';

              if (typeof qrCodeXml === 'string') {
                  if (qrCodeXml.startsWith(stringToRemoveFront)) {
                      qrCodeXml = qrCodeXml.slice(stringToRemoveFront.length);
                  }
                  
                  if (qrCodeXml.endsWith(stringToRemoveBack)) {
                      qrCodeXml = qrCodeXml.slice(0, -stringToRemoveBack.length);
                  }
              }

        this.qrCodeUrl = 'data:image/svg+xml;base64,' + btoa(qrCodeXml);
        this.secretKey = response.secretKey
      }, error => {
        this.$notify({
            type: 'danger',
            message: error.message,
            icon: 'fa fa-save'
          })
      })
      this.isLoadingGetGoogleTwoFactorAuth = false
    },
    async onClickSaveGoogle(){
      const userData = this.$store.getters.userData
      this.isLoadingSaveGoogleTwoFactorAuth = true
  
      const payload = {
        userId: userData.id,
        two_factor_auth: true,
        two_factor_auth_type: 'google',
        secretKey: this.secretKey,
      }

      await this.$store.dispatch('settingTwoFactorAuth', payload)
        .then(response => {
          this.$notify({
              type: 'success',
              message: response.message,
              icon: 'far fa-save'
          });
        }, error => {
          this.$notify({
            type: 'danger',
            message: error.message,
            icon: 'fa fa-save'
          })
        })
        this.isLoadingSaveGoogleTwoFactorAuth = false
        this.modals.googleAuth = false
        this.onCloseModalTwoFactorAuth()
    }
  },
}
</script>

<style>

</style>